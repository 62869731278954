import { Fragment, useEffect, useState } from 'react';
import Image from 'next/image';
import { individualTemplateColor } from '../../util/ColorUtil';
import {
  getEditorColors,
  isHD,
  isItUndefined,
  isMobile,
  isTablet,
  modifyLinks,
} from '../../util/DataUtil';
import { useRouter } from 'next/router';
import { cleanHTML } from '../utils/htmlUtils';

const GraphicHero = ({
  children,
  width,
  individualPageForceBackgroundColor,
  key,
}) => {
  const [isScreenMobile, setIsScreenMobile] = useState(false); // State to track if the screen is <= 575px

  useEffect(() => {
    // Function to check the screen width
    const checkScreenWidth = () => {
      setIsScreenMobile(window.innerWidth <= 575);
    };

    // Initial check
    checkScreenWidth();

    // Add event listener for window resize
    window.addEventListener('resize', checkScreenWidth);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkScreenWidth);
    };
  }, []);

  useEffect(() => {
    if (isScreenMobile) {
      // Select the hero-right element
      const heroRightElement = document.querySelector('.hero-right');
      const heroRightSpan = document.querySelector('.hero-right span');
      const heroRightImg = document.querySelector('.hero-right span img');

      // Clear all inline styles
      if (heroRightElement) {
        heroRightSpan.removeAttribute('style');
        heroRightImg.removeAttribute('style');
      }
    }
  }, [isScreenMobile]);

  let animatedWords = [];
  let part = 0;
  let partIndex = 0;
  let intervalVal;
  let element;
  const router = useRouter();
  const emphasizedTextColor = getEditorColors(
    isItUndefined(children?.field_formatted_title),
    isItUndefined(children?.field_description)
  ).filter((x) => x)[0];
  let animationDuration = isItUndefined(
    children?.field_graphic_hero_animation_dur
  )
    ? isItUndefined(children?.field_graphic_hero_animation_dur) * 1000
    : '2000';

  children?.field_graphic_hero_animated_word?.map((item, key) => {
    animatedWords.push(item?.value);
  });

  const typeWord = () => {
    if (!element) {
      return;
    }

    if (animatedWords.length === 0) {
      return;
    }

    let text = animatedWords[part].substring(0, partIndex + 1);
    let blinkingCursor = document.getElementsByClassName('blinking-cursor');
    element.innerHTML = text;
    partIndex++;

    if (text === animatedWords[part]) {
      clearInterval(intervalVal);
      setTimeout(function () {
        if (
          animatedWords.indexOf(animatedWords[part]) !==
          animatedWords.length - 1
        ) {
          intervalVal = setInterval(deleteWord, 100);
        } else {
          if (blinkingCursor && blinkingCursor?.length > 0) {
            blinkingCursor[0].style.display = 'none';
          }
        }
      }, animationDuration);
    }
  };

  const deleteWord = () => {
    if (!element) {
      return;
    }

    if (animatedWords.length === 0) {
      return;
    }

    let text = animatedWords[part].substring(0, partIndex - 1);
    element.innerHTML = text;
    partIndex--;

    if (text === '') {
      clearInterval(intervalVal);

      if (part === animatedWords.length - 1) {
        part = 0;
      } else {
        part++;
      }

      partIndex = 0;

      setTimeout(function () {
        intervalVal = setInterval(typeWord, 100);
      }, 200);
    }
  };

  const getOverlayColor = (color) => {
    switch (color) {
      case '0':
        return 'light';
      case '1':
        return 'dark';
      default:
        return 'light';
    }
  };

  useEffect(() => {
    let heightOfTitle =
      document.getElementsByClassName('hero-title')[0].offsetHeight;

    if (isMobile(width) && heightOfTitle > 161) {
      document.getElementsByClassName('hero-left')[0].style.paddingTop = '48px';
    } else if (isTablet(width) && heightOfTitle > 127) {
      document.getElementsByClassName('hero-left')[0].style.paddingTop = '96px';
    } else if (isHD(width) && heightOfTitle > 258) {
      document.getElementsByClassName('hero-left')[0].style.paddingTop =
        '128px';
    }

    if (typeof window !== 'undefined') {
      element = document.getElementById('text');
    }
    intervalVal = setInterval(typeWord, 100);
  }, []);

  return (
    <div
      className={`graphic-hero`}
      key={key}
      style={{
        backgroundColor: individualTemplateColor(
          children?.field_customer_background[0]?.value,
          individualPageForceBackgroundColor
        ),
      }}
    >
      <div className="inner-container">
        <div
          className={`hero-left ${isItUndefined(children?.field_graphic_hero_mask) ? `mask-${isItUndefined(children.field_graphic_hero_mask)}` : ''} ${router.asPath.includes('expertise') ? 'expertise' : ''}`}
        >
          {isItUndefined(children?.field_formatted_title) ? (
            <div
              className={`hero-title ${emphasizedTextColor ? emphasizedTextColor : ''}`}
              dangerouslySetInnerHTML={{
                __html: cleanHTML(
                  modifyLinks(isItUndefined(children?.field_formatted_title))
                ),
              }}
            />
          ) : (
            <Fragment>
              <div className={'title-part-1'}>
                <div
                  className={`hero-title ${emphasizedTextColor ? emphasizedTextColor : ''}`}
                  dangerouslySetInnerHTML={{
                    __html: cleanHTML(
                      modifyLinks(
                        isItUndefined(children?.field_graphic_hero_title_line_1)
                      )
                    ),
                  }}
                />
                <div className={'animation-wrapper'}>
                  <div
                    id="text"
                    className={
                      'animated-text ' +
                      getOverlayColor(
                        isItUndefined(
                          children?.field_graphic_hero_overlay_color
                        )
                      )
                    }
                  />
                  <div
                    id="cursor"
                    className={
                      'blinking-cursor ' +
                      getOverlayColor(
                        isItUndefined(
                          children?.field_graphic_hero_overlay_color
                        )
                      )
                    }
                  />
                </div>
              </div>
              <div
                className={`hero-title ${emphasizedTextColor ? emphasizedTextColor : ''}`}
                dangerouslySetInnerHTML={{
                  __html: cleanHTML(
                    modifyLinks(
                      isItUndefined(children.field_graphic_hero_title_line_2)
                    )
                  ),
                }}
              />
            </Fragment>
          )}
          <span
            dangerouslySetInnerHTML={{
              __html: cleanHTML(
                modifyLinks(isItUndefined(children?.field_description))
              ),
            }}
            className={`description ${emphasizedTextColor ? emphasizedTextColor : ''}`}
          />
        </div>
        <div
          className={`hero-right ${isItUndefined(children?.field_graphic_hero_mask) ? `mask-${isItUndefined(children?.field_graphic_hero_mask)}` : ''}`}
        >
          <Image
            alt="graphic-hero"
            src={children?.field_hero_image?.webimage}
            layout="fill"
          />
        </div>
      </div>
    </div>
  );
};

export default GraphicHero;
